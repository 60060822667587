.App {
    width: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.App::-webkit-scrollbar {
    display: none;
}
