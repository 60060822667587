@keyframes fade_in {
  from {
    transform: translateY(25px);
    opacity: 0;
  }
  to {
    transform: translate(0px);
    opacity: 1;
  }
}

.Title {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  justify-items: center;
  align-self: center;
  width: 80%;
}

.Center {
  align-content: center !important;
}

.Left {
  align-content: start !important;
}

.Right {
  align-content: end !important;
}

.Text.Right {
  align-self: end;
}

.Text.Left {
  align-self: start;
}

.Text {
  font-size: 5vw;
  font-weight: 100;
  letter-spacing: 0.3em;
  animation: fade_in 2s ease-in-out forwards;
  align-self: center;
  opacity: 0;
}

.Line.Right {
  margin-right: 0;
  width:60%;
}

.Line.Left {
  margin-left: 0;
  width:60%;
}

.Line {
  justify-self: center;
  align-self: center;
  width: 20%;
  margin-top: 3vw;
  border: 1px solid black;
  background-color: black;
  animation: fade_in 1s ease-in-out 1s forwards;
  opacity: 0;
}

@media (max-width: 800px) {
  .Line {
    margin-top: 3vh;
    width: 40%;
  }

  .Text {
    font-size: 4vh;
  }
}
