@keyframes fade_in {
    from {
        transform: translateY(25px);
        opacity: 0;
    }
    to {
        transform: translate(0px);
        opacity: 1;
    }
}

a {
    text-decoration: none;
    color: inherit;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    display: block;
    width: 100%;
}

.Body-Elements:hover .Body-Text:not(:hover){
    color:rgb(230, 230, 230);
    filter:blur(1px);
    opacity: 0%;
}


.Body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    align-self: center;
    flex-basis: auto;
    margin-top: 2em;
    width: 80%;
}

.Left {
    text-align: left;
    justify-content: start;
}

.Right {
    text-align: right;
    justify-content: end;
}

.Body-Text.Left {
    text-align: left;
    justify-content: start;
}

.Body-Text.Right {
    justify-content: end;
    text-align: right;
}

.Body-Text {
    font-size: 2vw;
    font-weight: 100;
    animation: fade_in 2s ease-in-out 1.2s forwards;
    align-self: center;
    text-align: right;
    opacity: 0;
    white-space: pre-line;
}

.Body-Elements {
    display: block;
}

.Line {
    justify-self: center;
    align-self: center;
    width: 20%;
    margin-top: 3vw;
    border: 1px solid black;
    background-color: black;
    animation: fade_in 1s ease-in-out 1s forwards;
    opacity: 0;
}

@media (max-width: 800px) {
    .Line {
        margin-top: 3vh;
        width: 40%;
    }

    .Body-Text {
        font-size: 2vh;
    }
}
